<template>
<div>
  <v-dialog v-model="show" width="1200" @input="$emit('close')">
    <v-card>
      <v-card-text>
        <div class="row pt-1 border-bottom">
          <div class="col-md-12">
            <div class="d-flex justify-space-between align-center mt-2">
              <SvgIcon class="text-2xl font-semibold" text=" Cash Wallet Transactions" style="color: black" >
              </SvgIcon>
              <v-btn  fab x-small class="shadow-0" @click="closeModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <v-row class="mt-3">
          <div class="col-md-12">
            <table class="table border-collapse text-center">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded text-center">
                <th>Timestamp</th>
                <th>Operator</th>
                <th>Amount</th>
                <th>Action</th>
                <th>Note</th>
              </tr>
              </thead>
              <tbody v-if="transactions && transactions.length > 0">
              <tr
                  v-for="(transaction, index) in transactions"
                  :key="index"
                  class="text-left"
              >
                <td>
                  {{ transaction.created_at | timeStamp }}
                </td>
                <td>
                  {{ transaction.operator_name }}
                </td>
                <td>
                  {{ transaction.amount | toCurrency }}
                </td>
                <td :class="[transaction.action === 'Deduct' ? 'red-font' : 'green-font']">
                  {{ transaction.action }}
                </td>
                <td>
                  {{ transaction.note }}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="5" class="text-center">No records</td>
              </tr>
              </tbody>
            </table>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions>

        <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="showForm = true"
        >Add Transaction</v-btn>
        <v-spacer/>
        <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="closeModal"
        >Close</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

  <v-dialog v-model="showForm" width="500">
    <v-card>
      <v-form ref="form">
        <v-card-text>
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Cash Wallet" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="closeAddEditForm">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="">
          <v-container>
            <v-row justify="center">
              <v-col md="6">
                <label for="">Amount</label>
                <v-text-field
                    :prefix="currencyCode"
                    v-model="transactionData.amount"
                    :rules="[
                      (v) => {
                        if (v) {
                          if (isNaN(v)) {
                            return 'Amount must be a number';
                          }
                        }
                        return true;
                      },
                    ]"
                    label=""
                    class="q-text-field shadow-0"
                    outlined
                    dense
                    hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <label for="">Action</label>
                <v-autocomplete
                    v-model="transactionData.action"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    item-text="name"
                    item-value="name"
                    label=""
                    :items="actions"
                >
                </v-autocomplete>
              </v-col>

              <v-col md="12">
                <label for="">Note</label>
                <v-textarea
                    v-model="transactionData.note"
                    background-color="#fff"
                    outlined
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="ma-2 "
              text
              @click="closeAddEditForm"
          >Close</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              @click="saveCashWallet"
              text
          >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
  <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
  ></confirm-model>

</div>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "CustomerCashWallet.vue",
  components: {SvgIcon},
  props: {
    show: { type: Boolean, default: false },
    customer_id: { type: Number, default: null },
  },
  data() {
    return {
      actions:[
        {
          name:'Add',
        },
        {
          name:'Deduct'
        }
      ],
      transactionData:{
        amount:null,
        action:'add',
        note:'',
      },
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      showForm:false,
      transactions: [],
    };
  },
  watch: {
    show(val) {
      // customer_id(val) {
      if (!val) {
        this.transactions = [];
      }
      else {
        this.getTransactions();
      }
    },
  },
  mounted() {},
  computed: {},

  methods:{
    closeAddEditForm(){
      this.transactionData = {
        amount:null,
        action:'add',
        note:'',
      };
      this.showForm = false;
    },
    getTransactions() {
      this.showLoader("Loading...");

      this.$http
          .get(
              `venues/customers/cash-wallet/${this.customer_id}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.hideLoader();
              let data = response.data.data;
              this.transactions = [];
              data.forEach((d) => {
                this.transactions.push({
                  id: d.id,
                  amount: d.amount,
                  action: d.action,
                  note: d.note,
                  created_at: d.created_at,
                  operator_name: d.operator_name,
                });
              });
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    saveCashWallet(){
      this.showLoader("Loading...");

      this.$http
          .post(
              `venues/customers/cash-wallet/${this.customer_id}`,this.transactionData
          )
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.hideLoader();
              this.closeAddEditForm();
              this.getTransactions();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });

    },
    closeModal() {
      this.$emit("close");
    },
     confirmActions(data) {
      console.log(data);
    },
  }


}
</script>
<style scoped>
.red-font{
  color: red;
}
.green-font{
  color: green;
}
</style>