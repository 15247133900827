var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"800"},on:{"input":function($event){return _vm.$emit('close')}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"row pt-1 border-bottom"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"d-flex justify-space-between align-center mt-2"},[_c('SvgIcon',{staticClass:"text-2xl font-semibold",staticStyle:{"color":"black"},attrs:{"text":" Products Wallet"}}),_c('v-btn',{staticClass:"shadow-0",attrs:{"fab":"","x-small":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])])]),_c('v-card-text',[_c('v-row',{staticClass:"mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table border-collapse text-center"},[_c('thead',[_c('tr',{staticClass:"opacity-70 tr-neon tr-rounded text-center"},[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Quantity")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Action")])])]),(_vm.products && _vm.products.length > 0)?_c('tbody',_vm._l((_vm.products),function(product,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(product.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(product.type)+" ")]),_c('td',[_vm._v(" "+_vm._s(product.quantity)+" ")]),_c('td',[_vm._v(_vm._s(product.status == 1 ? "Active" : "Inactive"))]),_c('td',[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.editProduct(product.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue","dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_vm._v(" Edit ")])],1),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteProduct(product.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_vm._v(" Remove ")])],1)],1)])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_vm._v("No records")])])])])])])],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2 white--text blue-color",attrs:{"color":" darken-1","text":""},on:{"click":function($event){_vm.showForm = true}}},[_vm._v("Add Products")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 white--text blue-color",attrs:{"color":" darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v("Close")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-card',[_c('v-form',{ref:"form"},[_c('v-card-text',[_c('div',{staticClass:"row pt-1 border-bottom"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"d-flex justify-space-between align-center mt-2"},[_c('SvgIcon',{staticClass:"text-2xl font-semibold",staticStyle:{"color":"black"},attrs:{"text":" Wallet Product"}}),_c('v-btn',{staticClass:"shadow-0",attrs:{"fab":"","x-small":""},on:{"click":_vm.closeAddEditForm}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])])]),_c('v-card-text',{},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","item-text":"name","item-value":"id","label":"Product","items":_vm.allProducts},model:{value:(_vm.form_wallet_product.product_id),callback:function ($$v) {_vm.$set(_vm.form_wallet_product, "product_id", $$v)},expression:"form_wallet_product.product_id"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"rules":[
                    function (v) {
                      if (v) {
                        if (isNaN(v)) {
                          return 'Amount must be a number';
                        }
                      }
                      return true;
                    } ],"label":"Quantity","outlined":""},model:{value:(_vm.form_wallet_product.quantity),callback:function ($$v) {_vm.$set(_vm.form_wallet_product, "quantity", $$v)},expression:"form_wallet_product.quantity"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2 ",attrs:{"text":""},on:{"click":_vm.closeAddEditForm}},[_vm._v("Close")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 white--text blue-color",attrs:{"color":" darken-1","text":""},on:{"click":_vm.saveProductWallet}},[_vm._v("Save")])],1)],1)],1)],1),_c('confirm-model',_vm._b({on:{"confirm":_vm.confirmActions,"close":function($event){_vm.confirmModel.id = null}}},'confirm-model',_vm.confirmModel,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }